

console.log('Current working directory:', window.location.href);

console.log('JavaScript file is being executed...');

// array of image sources
const images = [
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/uhdg2djcshsgkeeqobgj.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/aetbegh8lyh4yw5ze1sq.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/z9kburkpj0yiioztdv1f.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/sztrvoyxy1hki3c9uduo.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/ufstoe2fifmn0bfcbphq.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727678897/cats/Kittens%20Wurf%20D/lnn1qkgfxftkjceunv69.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679306/images/rej44tpqwei2jnqhyo0m.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/hrfvwnemxv4tvpoli16v.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/hsj0f70vjuanbu3gmmcm.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/z88ikl8agfyalnxq1nlm.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/pmtdoghht7lflgl7xrrp.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/c1nekptignhyxtpxr04w.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679305/images/wmrk0tz1fj7ijwscjoz8.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679304/images/f9kzrg85fagriw0xm3dk.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679304/images/oohvx2zverlsgwbd9bv8.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679304/images/whelvkq3jtabjoeukgij.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679304/images/mgt93oewrwh9din2z1ty.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679303/images/o9yciyb2rlg2alvumklp.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679303/images/jr6gzbp8uu97ltsckkvn.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679303/images/wvz6sydfbyqilvr9y9cx.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1724679302/images/t67dxapvyofiontldebt.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727681313/cats/Kittens%20Wurf%20D/ffnsotzt9znekqiqtebu.jpg',
  'https://res.cloudinary.com/dctjnykyl/image/upload/v1727682814/cats/Kittens%20Wurf%20D/diimbd2i8mlu483tdbl9.jpg'
];

const carousel = document.querySelector('.gallery-carousel');
console.log('Carousel element:', carousel);

// set the initial image index
let currentIndex = 0;

// Create the initial image element and add it to the carousel
const img = document.createElement('img');
console.log('Creating image element...');
img.src = images[currentIndex];
console.log(img.src);
img.alt = `Kitten ${currentIndex + 1}`;
img.classList.add('active');
carousel.appendChild(img);

// get the prev and next buttons
const prevBtn = document.querySelector('.prev-btn');
const nextBtn = document.querySelector('.next-btn');

// add event listeners to the buttons
prevBtn.addEventListener('click', () => {
  currentIndex = (currentIndex - 1 + images.length) % images.length;
  updateCarousel();
});

nextBtn.addEventListener('click', () => {
  currentIndex = (currentIndex + 1) % images.length;
  updateCarousel();
});



// update the carousel
function updateCarousel() {
  console.log('Updating carousel, current index:', currentIndex);
  // remove the old image
  const oldImg = carousel.querySelector('img');
  if (oldImg) {
    carousel.removeChild(oldImg);
  }

  // create a new image element
  const newImg = document.createElement('img');
  newImg.src = images[currentIndex];
  console.log('New image src:', newImg.src);
  newImg.alt = `Kitten ${currentIndex + 1}`;
  newImg.classList.add('active');

  newImg.onload = function() {
    console.log('Image loaded successfully:', this.src);
  };

  newImg.onerror = function() {
    if (newImg.src.includes('403')) {
      console.error('Server does not have permission to access the image file');
    }
    console.error('Error loading image:', newImg.src);
  };

  // append the new image to the carousel
  carousel.appendChild(newImg);
}

updateCarousel();


